
Vue.component('v-select', VueSelect.VueSelect);

if (document.querySelectorAll('[data-widget="team"]').length) {
    var widget = new Vue({
        el: '[data-widget="team"]',
        props: {
            roles: {
                type: Object,
                default: function() {
                    return team.roles;
                }
            },
            areas: {
                type: Object,
                default: function() {
                    return team.areas;
                }
            },
            members: {
                type: Object,
                default: function() {
                    return team.members;
                }
            }
        },
        data: {
            query: '',
            role: null,
            area: []
        },
        methods: {
            strings: function (key) {
                return team.strings[key];
            }
        },
        computed: {
            rolesArray: function () {
                var arr = [];
                for (var key in this.roles) {
                    arr.push({
                        value: key,
                        label: this.roles[key]
                    });
                }
    
                return arr;
            },
            filtered: function() {
                var output = {},
                    query = this.query.toLowerCase(),
                    area = _.map(this.area, function (v) {
                        return parseInt(v);
                    }),
                    role = this.role ? parseInt(this.role['value']) : null;
    
                for (var key in this.members) {
                    var arr = this.members[key].filter(function(member) {
                        var name = member.name.toLowerCase(),
                            areaInt = _.intersection(member.area, area);
    
                        return (!query || name.indexOf(query) >= 0) &&
                               (!area.length || areaInt.length) &&
                               (!role || member.role.indexOf(role) >= 0);
                    }, this);
    
                    if (arr.length) {
                        output[key] = arr;
                    }
                }
    
                return output;
            }
        }
    });
}